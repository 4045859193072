import { render, staticRenderFns } from "./ThankYouPage.vue?vue&type=template&id=f2b53dba&scoped=true&"
import script from "./ThankYouPage.vue?vue&type=script&lang=js&"
export * from "./ThankYouPage.vue?vue&type=script&lang=js&"
import style0 from "./ThankYouPage.vue?vue&type=style&index=0&id=f2b53dba&prod&scoped=true&lang=scss&"
import style1 from "./ThankYouPage.vue?vue&type=style&index=1&id=f2b53dba&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2b53dba",
  null
  
)

export default component.exports
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import qInstall from "../../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QToggle});
