<template>
  <AppCard
    body-class="flex column q-pa-lg"
    header-color="bg-grey-11"
    title-class="text-14-700"
    :title="$t('title.widgetType')"
    bordered
  >
    <div class="form-builder__option img-selector">
      <div
        v-for="type in widgetTypes"
        :key="type"
        :class="['option', { active: isWidgetType(type) }]"
        @click="formPart.thankYouPageWidgetsType = type"
      >
        <q-img
          :src="require(`assets/widget_${type}.svg`)"
          class="option-img"
          width="64px"
          height="79px"
        />
        {{ $t(`label.${type}`) }}
      </div>
    </div>
    <div class="form-builder__option form-builder__option_horizontal">
      <AppColorPicker
        v-model="formPart.thankYouPageWidgetsFirstAreaBackgroundColor"
        :label="$t('label.thankYouPageWidgetsFirstAreaBackgroundColor')"
      />
      <AppColorPicker
        v-model="formPart.thankYouPageWidgetsSecondAreaBackgroundColor"
        :label="$t('label.thankYouPageWidgetsSecondAreaBackgroundColor')"
      />
      <AppSliderInput
        v-model="formPart.thankYouPageWidgetsBorderRadius"
        :label="$t('label.thankYouPageWidgetsBorderRadius')"
        :min="0"
        :max="90"
        input-container-class="grid-3"
      />
    </div>
  </AppCard>
</template>

<script>
import AppCard from 'common/components/AppCard';
import AppColorPicker from 'common/components/AppColorPicker';
import AppSliderInput from 'common/components/AppSliderInput';
import { builderMixin } from 'pages/dashboard/form/mixins';

export default {
  name: 'WidgetType',
  stepName: ENUMS.STEPS.THANK_YOU_WIDGET,
  components: {
    AppCard,
    AppSliderInput,
    AppColorPicker,
  },
  mixins: [builderMixin],
  data() {
    return {
      widgetTypes: Object.values(ENUMS.WIDGET_TYPES),
    };
  },
  methods: {
    isWidgetType(type) {
      return this.formPart.thankYouPageWidgetsType === type;
    },
  },
};
</script>
