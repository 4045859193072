<template>
  <AppCard
    body-class="flex column q-pa-lg"
    header-color="bg-grey-11"
    title-class="text-14-700"
    :title="$t('title.telegramReminders')"
    bordered
  >
    <template #titleRight>
      <AppToggle v-model="formPart.thankYouPageWidgetsTelegramReminders"/>
    </template>
    <template v-if="formPart.thankYouPageWidgetsTelegramReminders">
      <div class="form-builder__option form-builder__option_horizontal">
        <AppInput
          v-model="formPart.thankYouPageWidgetsTelegramRemindersSignatureText"
          :label="$t('label.signatureText')"
        />
      </div>
      <div class="form-builder__option form-builder__option_horizontal">
        <AppInput
          v-model="formPart.thankYouPageWidgetsTelegramRemindersLabel"
          :label="$t('label.label')"
        />
        <AppToggle
          v-model="formPart.thankYouPageWidgetsTelegramRemindersIconEnabled"
          :label="$t('label.showIconForField')"
          left-label
        />
        <template v-if="formPart.thankYouPageWidgetsTelegramRemindersIconEnabled">
          <div class="icon-selector">
            <div>{{ $t('label.icon') }}</div>
            <AppIconPicker v-model="formPart.thankYouPageWidgetsTelegramRemindersIcon"/>
            <AppButtonToggle
              v-model="formPart.thankYouPageWidgetsJoinButtonIconPosition"
              :options="iconPositionOptions"
              toggle-color="btn-toggle-selected"
              toggle-text-color="black"
              text-color="black"
              padding="12px 25px"
            />
          </div>
        </template>
      </div>
    </template>
  </AppCard>
</template>

<script>
import AppCard from 'common/components/AppCard';
import AppIconPicker from 'common/components/AppIconPicker';
import AppInput from 'common/components/AppInput';
import AppToggle from 'common/components/AppToggle';
import AppButtonToggle from 'common/components/buttons/AppButtonToggle';
import { builderMixin } from 'pages/dashboard/form/mixins';

export default {
  name: 'TelegramReminders',
  stepName: ENUMS.STEPS.THANK_YOU_WIDGET,
  components: {
    AppCard,
    AppButtonToggle,
    AppIconPicker,
    AppInput,
    AppToggle,
  },
  mixins: [builderMixin],
  data() {
    return {
      iconPositionOptions: [
        { label: this.$t('button.left'), value: 'left' },
        { label: this.$t('button.right'), value: 'right' },
      ],
    };
  },
};
</script>
