<template>
  <AppCard
    body-class="flex column q-pa-lg"
    header-color="bg-grey-11"
    title-class="text-14-700"
    :title="$t('title.joinButton')"
    bordered
  >
    <template #titleRight>
      <AppToggle v-model="formPart.thankYouPageWidgetsJoinButton"/>
    </template>
    <template v-if="formPart.thankYouPageWidgetsJoinButton">
      <div class="form-builder__option form-builder__option_horizontal">
        <AppInput
          v-model="formPart.thankYouPageWidgetsJoinButtonText"
          :label="$t('label.thankYouPageWidgetsJoinButtonText')"
        />
        <AppColorPicker
          v-model="formPart.thankYouPageWidgetsJoinButtonFontColor"
          :label="$t('label.fontColor')"
        />
        <AppSliderInput
          v-model="formPart.thankYouPageWidgetsJoinButtonFontSize"
          :label="$t('label.fontSize')"
          :max="64"
          input-container-class="grid-3"
        />
        <AppColorPicker
          v-model="formPart.thankYouPageWidgetsJoinButtonBackgroundColor"
          :label="$t('label.backgroundColor')"
        />
      </div>
      <div class="form-builder__option form-builder__option_horizontal">
        <AppColorPicker
          v-model="formPart.thankYouPageWidgetsJoinButtonBorderColor"
          :label="$t('label.borderColor')"
        />
        <AppSliderInput
          v-model="formPart.thankYouPageWidgetsJoinButtonBorderRadius"
          :label="$t('label.borderRadius')"
          :max="64"
          input-container-class="grid-3"
        />
      </div>
      <div class="form-builder__option form-builder__option_horizontal">
        <AppToggle
          v-model="formPart.thankYouPageWidgetsJoinButtonIconEnabled"
          :label="$t('label.thankYouPageWidgetsJoinButtonIconEnabled')"
          left-label
        />
        <template v-if="formPart.thankYouPageWidgetsJoinButtonIconEnabled">
          <div class="icon-selector">
            <div>{{ $t('label.icon') }}</div>
            <AppIconPicker v-model="formPart.thankYouPageWidgetsJoinButtonIcon"/>
            <AppButtonToggle
              v-model="formPart.thankYouPageWidgetsJoinButtonIconPosition"
              :options="iconPositionOptions"
              toggle-color="btn-toggle-selected"
              toggle-text-color="black"
              text-color="black"
              padding="12px 25px"
            />
          </div>
          <AppColorPicker
            v-model="formPart.thankYouPageWidgetsJoinButtonIconColor"
            :label="$t('label.colorIcon')"
          />
        </template>
      </div>
    </template>
  </AppCard>
</template>

<script>
import AppCard from 'common/components/AppCard';
import AppColorPicker from 'common/components/AppColorPicker';
import AppIconPicker from 'common/components/AppIconPicker';
import AppInput from 'common/components/AppInput';
import AppSliderInput from 'common/components/AppSliderInput';
import AppToggle from 'common/components/AppToggle';
import AppButtonToggle from 'common/components/buttons/AppButtonToggle';
import { builderMixin } from 'pages/dashboard/form/mixins';

export default {
  name: 'JoinButton',
  stepName: ENUMS.STEPS.THANK_YOU_WIDGET,
  components: {
    AppButtonToggle,
    AppCard,
    AppColorPicker,
    AppIconPicker,
    AppSliderInput,
    AppInput,
    AppToggle,
  },
  mixins: [builderMixin],
  data() {
    return {
      iconPositionOptions: [
        { label: this.$t('button.left'), value: 'left' },
        { label: this.$t('button.right'), value: 'right' },
      ],
    };
  },
};
</script>
