<template>
  <AppCard
    body-class="flex column q-pa-lg"
    header-color="bg-grey-11"
    title-class="text-14-700"
    :title="$t('title.webinarHost')"
    bordered
  >
    <template #titleRight>
      <AppToggle v-model="formPart.thankYouPageWidgetsWebinarHost"/>
    </template>
    <template v-if="formPart.thankYouPageWidgetsWebinarHost">
      <div class="form-builder__option form-builder__option_horizontal">
        <AppInput
          v-model="formPart.thankYouPageWidgetsWebinarHostName"
          :label="$t('label.name')"
          :placeholder="$t('label.name')"
        />
        <AppInput
          v-model="formPart.thankYouPageWidgetsWebinarSignature"
          :label="$t('label.signature')"
          :placeholder="$t('label.signature')"
        />
        <div class="grid-2">
          <div>{{ $t('label.hostImage') }}</div>
          <AppImagePicker v-model="formPart.thankYouPageWidgetsWebinarHostImage"/>
        </div>
      </div>
    </template>
  </AppCard>
</template>

<script>
import AppCard from 'common/components/AppCard';
import AppImagePicker from 'common/components/AppImagePicker';
import AppInput from 'common/components/AppInput';
import AppToggle from 'common/components/AppToggle';
import { builderMixin } from 'pages/dashboard/form/mixins';

export default {
  name: 'WebinarHost',
  stepName: ENUMS.STEPS.THANK_YOU_WIDGET,
  components: {
    AppCard,
    AppImagePicker,
    AppInput,
    AppToggle,
  },
  mixins: [builderMixin],
};
</script>
