<template>
  <AppCard
    body-class="flex column q-pa-lg"
    header-color="bg-grey-11"
    title-class="text-14-700"
    :title="$t('title.addToCalendarButton')"
    bordered
  >
    <template #titleRight>
      <AppToggle v-model="formPart.thankYouPageWidgetsAddToCalendar"/>
    </template>
    <template v-if="formPart.thankYouPageWidgetsAddToCalendar">
      <div class="form-builder__option form-builder__option_horizontal">
        <AppColorPicker
          v-model="formPart.thankYouPageWidgetsAddToCalendarFontColor"
          :label="$t('label.fontColor')"
        />
        <AppColorPicker
          v-model="formPart.thankYouPageWidgetsAddToCalendarBackgroundColor"
          :label="$t('label.backgroundColor')"
        />
        <AppColorPicker
          v-model="formPart.thankYouPageWidgetsAddToCalendarBorderColor"
          :label="$t('label.borderColor')"
        />
        <AppSliderInput
          v-model="formPart.thankYouPageWidgetsAddToCalendarBorderRadius"
          :label="$t('label.borderRadius')"
          :min="0"
          :max="90"
          input-container-class="grid-3"
        />
        <AppSliderInput
          v-model="formPart.thankYouPageWidgetsAddToCalendarFontSize"
          :label="$t('label.fontSize')"
          :max="64"
          input-container-class="grid-3"
        />
      </div>
      <div class="form-builder__option form-builder__option_horizontal">
        <div class="flex justify-between items-center">
          <div>{{ $t('label.icon') }}</div>
          <AppIconPicker v-model="formPart.thankYouPageWidgetsAddToCalendarIcon"/>
        </div>
        <AppColorPicker
          v-model="formPart.thankYouPageWidgetsAddToCalendarIconColor"
          :label="$t('label.colorIcon')"
        />
      </div>
      <div class="form-builder__option form-builder__option_horizontal">
        <AppSelect
          v-model="formPart.thankYouPageWidgetsAddToCalendarAvailableCalendars"
          :options="Object.values($options.calendarOptions)"
          :label="$t('label.availableCalendars')"
          class="chip-select items-start"
          multiple
          stack-label
        >
          <template v-slot:selected-item="scope">
            <q-chip
              removable
              icon-remove="fa-solid fa-xmark"
              :tabindex="scope.tabindex"
              color="grey-3"
              text-color="black"
              class="chip-option"
              @remove="scope.removeAtIndex(scope.index)"
            >
              {{ scope.opt }}
            </q-chip>
          </template>
        </AppSelect>
      </div>
    </template>
  </AppCard>
</template>

<script>
import AppCard from 'common/components/AppCard';
import AppColorPicker from 'common/components/AppColorPicker';
import AppIconPicker from 'common/components/AppIconPicker';
import AppSelect from 'common/components/AppSelect';
import AppSliderInput from 'common/components/AppSliderInput';
import AppToggle from 'common/components/AppToggle';
import * as CALENDAR_TYPES from 'common/enums/calendarTypes';
import { builderMixin } from 'pages/dashboard/form/mixins';

export default {
  name: 'CalendarButton',
  calendarOptions: CALENDAR_TYPES,
  stepName: ENUMS.STEPS.THANK_YOU_WIDGET,
  components: {
    AppCard,
    AppSelect,
    AppIconPicker,
    AppSliderInput,
    AppColorPicker,
    AppToggle,
  },
  mixins: [builderMixin],
};
</script>
