<template>
  <AppCard
    body-class="flex column q-pa-lg"
    header-color="bg-grey-11"
    title-class="text-14-700"
    :title="$t('title.eventDate')"
    bordered
  >
    <template #titleRight>
      <AppToggle v-model="formPart.thankYouPageWidgetsEventDate"/>
    </template>
    <div
      v-if="formPart.thankYouPageWidgetsEventDate"
      class="form-builder__option form-builder__option_horizontal"
    >
      <AppColorPicker
        v-model="formPart.thankYouPageWidgetsEventDateFontColor"
        :label="$t('label.fontColor')"
      />
      <AppColorPicker
        v-model="formPart.thankYouPageWidgetsEventDateBackgroundColor"
        :label="$t('label.backgroundColor')"
      />
      <AppColorPicker
        v-model="formPart.thankYouPageWidgetsEventDateBorderColor"
        :label="$t('label.borderColor')"
      />
      <AppSliderInput
        v-model="formPart.thankYouPageWidgetsEventDateBorderRadius"
        :label="$t('label.borderRadius')"
        :min="0"
        :max="90"
        input-container-class="grid-3"
      />
      <AppSliderInput
        v-model="formPart.thankYouPageWidgetsEventDateFontSize"
        :label="$t('label.fontSize')"
        :max="64"
        input-container-class="grid-3"
      />
    </div>
  </AppCard>
</template>

<script>
import AppCard from 'common/components/AppCard';
import AppColorPicker from 'common/components/AppColorPicker';
import AppSliderInput from 'common/components/AppSliderInput';
import AppToggle from 'common/components/AppToggle';
import { builderMixin } from 'pages/dashboard/form/mixins';

export default {
  name: 'EventDate',
  stepName: ENUMS.STEPS.THANK_YOU_WIDGET,
  components: {
    AppCard,
    AppSliderInput,
    AppColorPicker,
    AppToggle,
  },
  mixins: [builderMixin],
};
</script>
