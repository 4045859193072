<template>
  <AppCard
    body-class="flex column q-pa-lg"
    header-color="bg-grey-11"
    title-class="text-14-700"
    :title="$t('title.headText')"
    bordered
  >
    <template #titleRight>
      <AppToggle v-model="formPart.thankYouPageWidgetsHead"/>
    </template>
    <template v-if="formPart.thankYouPageWidgetsHead">
      <div class="form-builder__option form-builder__option_horizontal">
        <AppInput
          v-model="formPart.thankYouPageWidgetsHeadText"
          class="app-input_textarea"
          type="textarea"
          :label="$t('label.thankYouPageWidgetsHeadText')"
          autogrow
        />
        <AppColorPicker
          v-model="formPart.thankYouPageWidgetsHeadFontColor"
          :label="$t('label.thankYouPageWidgetsHeadFontColor')"
        />
        <AppSliderInput
          v-model="formPart.thankYouPageWidgetsHeadFontSize"
          :label="$t('label.thankYouPageWidgetsHeadFontSize')"
          :max="64"
          input-container-class="grid-3"
        />
      </div>
      <div class="form-builder__option form-builder__option_horizontal">
        <AppInput
          v-model="formPart.thankYouPageWidgetsHeadDescription"
          class="app-input_textarea"
          type="textarea"
          :label="$t('label.description')"
          autogrow
        />
        <AppColorPicker
          v-model="formPart.thankYouPageWidgetsHeadDescriptionFontColor"
          :label="$t('label.thankYouPageWidgetsHeadDescriptionFontColor')"
        />
        <AppSliderInput
          v-model="formPart.thankYouPageWidgetsHeadDescriptionFontSize"
          :label="$t('label.thankYouPageWidgetsHeadDescriptionFontSize')"
          :max="64"
          input-container-class="grid-3"
        />
      </div>
    </template>
  </AppCard>
</template>

<script>
import AppCard from 'common/components/AppCard';
import AppColorPicker from 'common/components/AppColorPicker';
import AppInput from 'common/components/AppInput';
import AppSliderInput from 'common/components/AppSliderInput';
import AppToggle from 'common/components/AppToggle';
import { builderMixin } from 'pages/dashboard/form/mixins';

export default {
  name: 'HeadText',
  stepName: ENUMS.STEPS.THANK_YOU_WIDGET,
  components: {
    AppCard,
    AppSliderInput,
    AppColorPicker,
    AppInput,
    AppToggle,
  },
  mixins: [builderMixin],
};
</script>
