<template>
  <AppCard
    body-class="flex column q-pa-lg"
    header-color="bg-grey-11"
    title-class="text-14-700"
    :title="$t('title.reverseTimer')"
    bordered
  >
    <template #titleRight>
      <AppToggle v-model="formPart.thankYouPageWidgetsReverseTimer"/>
    </template>
    <template v-if="formPart.thankYouPageWidgetsReverseTimer">
      <div class="form-builder__option form-builder__option_horizontal">
        <AppColorPicker
          v-model="formPart.thankYouPageWidgetsReverseTimerFontColor"
          :label="$t('label.fontColor')"
        />
        <AppColorPicker
          v-model="formPart.thankYouPageWidgetsReverseTimerBackgroundColor"
          :label="$t('label.circleColor')"
        />
        <AppSliderInput
          v-model="formPart.thankYouPageWidgetsReverseTimerSize"
          :label="$t('label.elementSize')"
          :max="64"
          input-container-class="grid-3"
        />
      </div>
    </template>
  </AppCard>
</template>

<script>
import AppCard from 'common/components/AppCard';
import AppColorPicker from 'common/components/AppColorPicker';
import AppSliderInput from 'common/components/AppSliderInput';
import AppToggle from 'common/components/AppToggle';
import { builderMixin } from 'pages/dashboard/form/mixins';

export default {
  name: 'ReverseTimer',
  stepName: ENUMS.STEPS.THANK_YOU_WIDGET,
  components: {
    AppCard,
    AppSliderInput,
    AppColorPicker,
    AppToggle,
  },
  mixins: [builderMixin],
};
</script>
