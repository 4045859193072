<template>
  <div>
    <AppCard
      class="thank-you__panel"
      body-class="flex column q-px-lg q-py-sm"
      header-color="bg-grey-11"
      :title="$t('label.thankYou')"
      bordered
    >
      <div class="column">
        <AppToggle
          v-model="formPart.thankYouPageWidgets"
          class="toggle-ty-page"
          left-label
        >
          <div class="flex items-center justify-between">
            <div v-html="$t('label.customThankYouPage')"/>

            <AppButton
              v-if="canCopyFormEmbedCode"
              :label="$t(isThanksCodeCopied ? 'button.copied' : 'button.copyCode')"
              :color="isThanksCodeCopied ? 'grey-2' : 'white'"
              :icon-right="`fa-regular fa-${isThanksCodeCopied ? 'circle-check rotate' : 'copy'}`"
              icon-right-size="12px"
              align="left"
              class="text-15-400 form-data-button"
              text-color="black"
              padding="4px 11px"
              outline
              @click.stop="copyThankYouCode"
            />
          </div>
        </AppToggle>
      </div>
    </AppCard>

    <div
      v-if="formPart.thankYouPageWidgets"
      class="thank-you__panel settings q-gutter-y-md q-mt-md"
    >
      <WidgetType/>
      <HeadText/>
      <LinkEvent/>
      <JoinButton/>
      <CalendarButton/>
      <ReverseTimer/>
      <EventDate/>
      <WebinarHost/>
      <ReminderMessages/>
      <TelegramReminders/>
    </div>
  </div>
</template>

<script>
import AppToggle from 'common/components//AppToggle';
import AppButton from 'common/components//buttons/AppButton';
import AppCard from 'common/components/AppCard';
import { copyCodeMixin } from 'common/mixins';
import formCode from 'src/helpers/formCode';
import CalendarButton from './components/setting/CalendarButton';
import EventDate from './components/setting/EventDate';
import HeadText from './components/setting/HeadText';
import JoinButton from './components/setting/JoinButton';
import LinkEvent from './components/setting/LinkEvent';
import ReminderMessages from './components/setting/ReminderMessages';
import ReverseTimer from './components/setting/ReverseTimer';
import TelegramReminders from './components/setting/TelegramReminders';
import WebinarHost from './components/setting/WebinarHost';
import WidgetType from './components/setting/WidgetType';
import builderMixin from './mixins/builderMixin';

export default {
  name: 'ThankYouPage',
  stepName: ENUMS.STEPS.THANK_YOU_WIDGET,
  components: {
    ReminderMessages,
    TelegramReminders,
    WebinarHost,
    EventDate,
    ReverseTimer,
    CalendarButton,
    JoinButton,
    LinkEvent,
    HeadText,
    WidgetType,
    AppButton,
    AppToggle,
    AppCard,
  },
  mixins: [builderMixin, copyCodeMixin],
  data() {
    return {
      isThanksCodeCopied: false,
    };
  },
  computed: {
    code() {
      return formCode(this.form[ENUMS.STEPS.OTHER].guid, true);
    },
  },
  methods: {
    async copyThankYouCode() {
      if (this.isThanksCodeCopied) {
        return;
      }
      await this.copyCode(this.code);
      this.isThanksCodeCopied = true;
      setTimeout(() => {
        this.isThanksCodeCopied = false;
      }, 2000);
    },
  },
};
</script>

<style scoped lang="scss">
.thank-you__panel {
  ::v-deep {
    .toggle-ty-page {
      .q-toggle__label {
        width: calc(100% - 55px);
        padding-right: 20px;
      }
    }
  }
}
</style>

<style lang="scss">
.thank-you__panel.settings {
  gap: 16px;

  .color-picker {
    grid-template-columns: 1fr 1fr;
    max-width: unset !important;
  }
}
</style>
