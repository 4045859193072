<template>
  <AppCard
    body-class="flex column q-pa-lg"
    header-color="bg-grey-11"
    title-class="text-14-700"
    :title="$t('title.reminderMessages')"
    bordered
  >
    <template #titleRight>
      <AppToggle
        v-model="formPart.thankYouPageWidgetsPhoneFieldEnabled"
        left-label
      />
    </template>
    <template v-if="formPart.thankYouPageWidgetsPhoneFieldEnabled">
      <div class="form-builder__option form-builder__option_horizontal">
        <AppInput
          v-model="formPart.thankYouPageWidgetsPhoneFieldLabel"
          :placeholder="$t('placeholder.phoneNumber')"
          :label="$t('label.label')"
        />
        <AppInput
          v-model="formPart.thankYouPageWidgetsPhoneFieldPlaceholder"
          :placeholder="$t('placeholder.yourPhoneNumber')"
          :label="$t('label.placeholder')"
        />
        <AppInput
          v-model="formPart.thankYouPageWidgetsPhoneFieldErrorMessage"
          :placeholder="$t('placeholder.invalidPhoneNumber')"
          :label="$t('label.errorMessage')"
        />
        <AppInput
          v-model="formPart.thankYouPageWidgetsPhoneFieldSuccessMessage"
          :label="$t('label.successMessage')"
        />
      </div>
      <div class="form-builder__option form-builder__option_horizontal">
        <AppColorPicker
          v-model="formPart.thankYouPageWidgetsPhoneFieldBackgroundColor"
          :label="$t('label.fieldBackgroundColor')"
        />
        <AppColorPicker
          v-model="formPart.thankYouPageWidgetsPhoneFieldPlaceholderColor"
          :label="$t('label.fieldPlaceholderColor')"
        />
        <AppColorPicker
          v-model="formPart.thankYouPageWidgetsPhoneFieldTextColor"
          :label="$t('label.fieldTextColor')"
        />
        <AppColorPicker
          v-model="formPart.thankYouPageWidgetsPhoneFieldIconColor"
          :label="$t('label.fieldIconColor')"
        />
        <AppColorPicker
          v-model="formPart.thankYouPageWidgetsPhoneFieldLabelColor"
          :label="$t('label.labelColor')"
        />
        <AppSliderInput
          v-model="formPart.thankYouPageWidgetsPhoneFieldLabelFontSize"
          :label="$t('label.labelFontSize')"
          input-container-class="grid-3"
        />
        <AppSliderInput
          v-model="formPart.thankYouPageWidgetsPhoneFieldFontSize"
          :label="$t('label.fontSize')"
          input-container-class="grid-3"
        />
        <AppColorPicker
          v-model="formPart.thankYouPageWidgetsPhoneFieldBorderColor"
          :label="$t('label.borderColor')"
        />
        <AppSliderInput
          v-model="formPart.thankYouPageWidgetsPhoneFieldBorderRadius"
          :label="$t('label.borderRadius')"
          :min="0"
          :max="90"
          input-container-class="grid-3"
        />
        <AppToggle
          v-model="formPart.thankYouPageWidgetsPhoneFieldIconEnabled"
          :label="$t('label.showIconForField')"
          left-label
        />
        <div
          v-if="formPart.thankYouPageWidgetsPhoneFieldIconEnabled"
          class="icon-selector"
        >
          <div>{{ $t('label.icon') }}</div>
          <AppIconPicker v-model="formPart.thankYouPageWidgetsPhoneFieldIcon"/>
          <AppButtonToggle
            v-model="formPart.thankYouPageWidgetsPhoneFieldIconPosition"
            :options="iconPositionOptions"
            toggle-color="btn-toggle-selected"
            toggle-text-color="black"
            text-color="black"
            padding="12px 25px"
          />
        </div>
      </div>
      <div class="form-builder__option form-builder__option_horizontal">
        <AppToggle
          v-model="formPart.thankYouPageWidgetsPhoneFieldCountryDetectionEnabled"
          :label="$t('label.autoDetection')"
          left-label
        />
        <AppSelect
          v-model="formPart.thankYouPageWidgetsPhoneFieldCountries"
          :options="$options.countries"
          :label="$t('label.preSelectedCountries')"
          :class="['chip-select', {
            'chip-select_empty': !formPart.thankYouPageWidgetsPhoneFieldCountries.length,
          }]"
          :option-value="item => item.value.toLowerCase()"
          stack-label
          multiple
          emit-value
          map-options
        >
          <span
            v-if="!formPart.thankYouPageWidgetsPhoneFieldCountries.length"
            class="text-grey chip-select__placeholder"
            v-html="$t('placeholder.allCountries')"
          />
          <template v-slot:selected-item="scope">
            <q-chip
              removable
              icon-remove="fa-solid fa-xmark"
              :tabindex="scope.tabindex"
              class="chip-option"
              color="grey-3"
              text-color="black"
              @remove="scope.removeAtIndex(scope.index)"
            >
              {{ scope.opt.label }}, {{ scope.opt.phoneCode }}
            </q-chip>
          </template>
        </AppSelect>
      </div>
      <div class="form-builder__option form-builder__option_horizontal">
        <AppColorPicker
          v-model="formPart.thankYouPageWidgetsPhoneButtonBackgroundColor"
          :label="$t('label.buttonColor')"
        />
        <AppColorPicker
          v-model="formPart.thankYouPageWidgetsPhoneButtonIconColor"
          :label="$t('label.buttonIconColor')"
        />
        <div class="flex items-center justify-between">
          <div>{{ $t('label.icon') }}</div>
          <AppIconPicker v-model="formPart.thankYouPageWidgetsPhoneButtonIcon"/>
        </div>
      </div>
    </template>
  </AppCard>
</template>

<script>
import AppCard from 'common/components/AppCard';
import AppColorPicker from 'common/components/AppColorPicker';
import AppIconPicker from 'common/components/AppIconPicker';
import AppInput from 'common/components/AppInput';
import AppSelect from 'common/components/AppSelect';
import AppSliderInput from 'common/components/AppSliderInput';
import AppToggle from 'common/components/AppToggle';
import AppButtonToggle from 'common/components/buttons/AppButtonToggle';
import countries from 'common/helpers/data/countryCodes.json';
import { builderMixin } from 'src/pages/dashboard/form/mixins';

export default {
  name: 'ReminderMessages',
  stepName: ENUMS.STEPS.THANK_YOU_WIDGET,
  countries,
  components: {
    AppSliderInput,
    AppColorPicker,
    AppSelect,
    AppCard,
    AppButtonToggle,
    AppIconPicker,
    AppInput,
    AppToggle,
  },
  mixins: [builderMixin],
  data() {
    return {
      iconPositionOptions: [
        { label: this.$t('button.left'), value: 'left' },
        { label: this.$t('button.right'), value: 'right' },
      ],
    };
  },
};
</script>
